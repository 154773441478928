import React, { useEffect, useState } from "react";
import styles from "./verifyPage.module.css";
import logo2 from "../../assets/loginPage/logo-2.png";
import illustration from "../../assets/verifyPage/illustration.png";
import icon1 from "../../assets/verifyPage/icon-1.png";
import InputDataDecoder from "ethereum-input-data-decoder";
import { ABI } from "../../resources/getABI";
import validIcon from "../../assets/verifyPage/valid.png";
import { toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import ConfettiComponent from "../../components/ConfettiComponent";
import { useNavigate } from "react-router-dom";

let timeout;

function VerifyPage() {
  const [transactionHash, setTransactionHash] = useState("");
  const [validTransactionHash, setValidTransactionHash] = useState(false);
  const [transactionReceipt, setTransactionReceipt] = useState("");
  const [ipfsHash, setIpfsHash] = useState("");
  const [validIpfsHash, setValidIpfsHash] = useState(false);
  const [invalidIpfsHash, setInvalidIpfsHash] = useState(false);
  const [httpError, setHttpError] = useState(false);
  const [disableVerify, setDisableVerify] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (transactionHash) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        verifyTransaction();
      }, 1000);
    }
  }, [transactionHash]);

  const verifyTransaction = async (e) => {
    try {
      const res = await fetch(
        `https://api-ropsten.etherscan.io/api?module=proxy&action=eth_getTransactionByHash&txhash=${transactionHash}`
      );

      const data = await res.json();
      console.log(data);
      if (typeof data?.result === "string") {
        throw {
          message: "max limit reached",
        };
      }
      if (data?.result?.hash) {
        if (data.result.hash === transactionHash) {
          console.log("valid transaction");
          setValidTransactionHash(true);
          setTransactionReceipt(data);
          setHttpError(false);
        }
      } else {
        setValidTransactionHash(false);
        setIpfsHash("");
        setValidIpfsHash(false);
        setInvalidIpfsHash(false);
        toast.error("Please enter correct transaction ID to proceed further", {
          position: "bottom-center",
          autoClose: 7000,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please contact developer", {
        position: "bottom-center",
        autoClose: 5000,
      });
      setHttpError(true);
    }
  };

  const verifyIpfsHash = (receipt) => {
    const data = receipt.result.input;
    const decoder = new InputDataDecoder(ABI);
    const result = decoder.decodeData(data);
    const decodedInput = result.inputs[0];

    if (decodedInput === ipfsHash) {
      console.log("valid ipfs hash");
      setValidIpfsHash(true);
      setDisableVerify(true);
      setShowConfetti(true);

      setTimeout(() => {
        setShowConfetti(false);
      }, 7000);
    } else {
      setValidIpfsHash(false);
      setInvalidIpfsHash(true);
      toast.error("Please enter correct content ID to proceed further", {
        position: "bottom-center",
        autoClose: 7000,
      });
    }
  };

  const handlePreview = () => {
    window.open(`https://ipfs.io/ipfs/${ipfsHash}`, "_blank").focus();
  };

  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <img src={logo2} alt="" />
        <h2 onClick={() => navigate("/")}>Proofify</h2>
        <button className={styles.goBack} onClick={() => navigate(-1)}>
          go back
        </button>
      </div>
      <div className={styles.section}>
        <div className={styles.section1}>
          <img src={illustration} alt="" />
        </div>
        <div className={styles.section2}>
          <form>
            <label htmlFor="transaction-hash">
              <img src={icon1} alt="" />
              <img
                hidden={!validTransactionHash}
                className={styles.validIcon}
                src={validIcon}
                alt=""
              />
              Enter a valid transaction ID
              {validTransactionHash ? (
                <span className={styles.statusMessage}>
                  (verified successfully)
                </span>
              ) : (
                <span className={styles.errorStatusMessage}>
                  {transactionHash.length > 0 ? "* incorrect hash" : ""}
                </span>
              )}
            </label>
            <input
              value={transactionHash}
              onChange={(e) => {
                setTransactionHash(e.target.value);
              }}
              type="text"
              name="transaction-hash"
              id="transaction-hash"
              placeholder="paste your transaction id here..."
            />
          </form>
          <div className={styles.ipfsSection}>
            <label htmlFor="ipfs-hash">
              <img src={icon1} alt="" />
              <img
                hidden={!validIpfsHash}
                className={styles.validIcon2}
                src={validIcon}
                alt=""
              />
              Enter a valid content ID
              {validIpfsHash ? (
                <span className={styles.statusMessage}>
                  (verified successfully)
                </span>
              ) : (
                <span className={styles.errorStatusMessage}>
                  {invalidIpfsHash ? "* incorrect hash" : ""}
                </span>
              )}
            </label>
            <input
              value={ipfsHash}
              onChange={(e) => setIpfsHash(e.target.value)}
              type="text"
              name="ipfs-hash"
              id="ipfs-hash"
              placeholder="paste your content id here..."
              readOnly={!validTransactionHash}
            />
            <div>
              <button
                readOnly={!validTransactionHash}
                type="button"
                onClick={() => verifyIpfsHash(transactionReceipt)}
                // disabled={disableVerify}
              >
                Verify
              </button>
              <button
                hidden={!validIpfsHash}
                type="button"
                onClick={handlePreview}
              >
                Preview
              </button>
            </div>
          </div>
        </div>
      </div>
      {showConfetti && <ConfettiComponent />}
      {/* {httpError && <ToastContainer limit={1} />} */}
    </div>
  );
}

export default VerifyPage;
