import "./App.css";
import { Routes, Route, HashRouter } from "react-router-dom";
import LoginPage from "./pages/loginPage/LoginPage";
import HomePage from "./pages/homePage/HomePage";
import VerifyPage from "./pages/verifyPage/VerifyPage";
import { ToastContainer } from "material-react-toastify";
import { useContext, useEffect, useState } from "react";
import userContext from "./context/user/userContext";
import SignupPage from "./pages/signupPage/SignupPage";

function App() {
  const [userState, dispatch] = useContext(userContext);

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          {userState.isAuthenticated && (
            <Route path="/home" element={<HomePage />} />
          )}
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </HashRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
