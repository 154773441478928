import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./otpModal.module.css";
import otpIllustration from "../assets/signupPage/otp-illustration.png";
import OTPInput, { ResendOTP } from "otp-input-react";
import axios from "axios";
import { apiUrl } from "../config";
import { toast } from "material-react-toastify";
import userContext from "../context/user/userContext";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 350,
  bgcolor: "background.paper",
  borderRadius: 7,
  boxShadow: 24,
  p: 4,
};

export default function OtpModal({ handler }) {
  const [otp, setOtp] = React.useState("");
  console.log("OPT", otp);

  const [userState, dispatch] = React.useContext(userContext);
  const navigate = useNavigate();

  const handleVerify = async () => {
    const fd = new FormData();
    fd.append("email", handler.email);
    fd.append("password", handler.password);
    fd.append("otp", otp);

    try {
      const response = await axios.post(apiUrl + "/register", fd);
      if (response.data.message === "user has been registered successfully") {
        toast.success("Signed up successfully", {
          position: "bottom-center",
          autoClose: 5000,
        });

        dispatch({ type: "VALID-USER" });
        sessionStorage.setItem("proofifyUser", handler.email);
        navigate("/home");
      }
      if (response.data.message === "incorrect otp") {
        toast.error("Incorrect verification code!", {
          position: "bottom-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
      });
    }
  };

  const Completionist = () => (
    <div className={styles.sendOtpAgain}>
      <h4>Send the code again </h4>
      <p>4.30</p>
    </div>
  );

  return (
    <div>
      <Modal
        open={handler.open}
        onClose={() => handler.close(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.section}>
          <div className={styles.section1}>
            <h3>Enter verification code</h3>
            <span>We have just sent a verification code to demo@gmail.com</span>
            <div className={styles.otpContainer}>
              <OTPInput
                value={otp}
                onChange={(otp) => setOtp(otp)}
                OTPLength={6}
                otpType="number"
                disabled={false}
                className={styles.otpInput}
              />
            </div>
            <button onClick={handleVerify}>Verify</button>
            <Countdown date={Date.now() + 5000}>
              <Completionist />
            </Countdown>
          </div>
          <div className={styles.section2}>
            <img src={otpIllustration} alt="" />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
